import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {LEAD_CARE_MANAGER, SUBMIT_ANSWER, MEMBERS, SURVEY, SURVEY_COMMENTS} from 'constants/endpoints';
import {result} from 'lodash';
import {toast} from 'react-toastify';
import {deleteApi, get, post, put} from 'services/network';
export const showSurvey = async id => {
  try {
    const result = await post(
      `${SURVEY}`,
      {
        survey_response: {
          member_id: id,
        },
      },
      true,
    );
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const getSurveyHistory = async (id, status = '') => {
  try {
    const result = await get(`${MEMBERS}/${id}/survey_responses`, true);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const getSurveyAnswers = async id => {
  try {
    const result = await get(`${SURVEY}/${id}/review_answers`, true);
    return result;
  } catch (error) {
    console.log(result);
  }
};
export const submitQuestion = async (id, optionId) => {
  const payload = {
    answer: {
      selected_answer_options_attributes: optionId,
    },
  };
  try {
    const result = await put(`${SUBMIT_ANSWER}/${id}`, payload, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Answer Saved" />, {
      autoClose: 100,
      closeOnClick: true,
    });
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const submitAllQuestions = async id => {
  const payload = {
    survey_response: {
      status: 'completed',
    },
  };
  try {
    const result = await put(`${SURVEY}/${id}`, payload, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Answer Submitted" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const submitLeadCareManager = async (id, optionId) => {
  const payload = {
    survey_response: {
      lead_care_manager_id: optionId,
    },
  };
  try {
    const result = await put(`${SURVEY}/${id}`, payload, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Answer Saved" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const discardSurvey = async id => {
  try {
    const result = await deleteApi(`${SURVEY}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Survey deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText || 'delete'} />);
    console.log(result);
  }
};

export const getLeadCareManagersByLocation = memberId => {
  // const params = {
  //   page: obj?.page ? obj.page : 1,
  //   perPage: obj?.perPage ? obj.perPage : 10,
  //   search: obj?.search ? obj.search : '',
  //   filter: obj?.status ? obj.status : '',
  // };
  try {
    const result = get(`${LEAD_CARE_MANAGER}/filter_by_member_locations?member_id=${memberId}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getSurveyComments = async memberId => {
  try {
    const result = await get(
      `${SURVEY_COMMENTS}?filter[commentable_type]=Member&filter[commentable_id]=${memberId}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const createSurveyComment = async (memberId, content) => {
  try {
    const result = await post(
      `${SURVEY_COMMENTS}`,
      {
        comment: {
          content,
          commentable_type: 'Member',
          commentable_id: memberId,
        },
      },
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSurveyComment = async (commentId, content) => {
  try {
    const result = await put(
      `${SURVEY_COMMENTS}/${commentId}`,
      {
        comment: {
          content,
        },
      },
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
