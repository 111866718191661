import * as yup from 'yup';

export const careManagerSchema = yup.object().shape({
  // first_name: yup
  //   .string()
  //   .matches(/^[A-Za-z\s'-]{1,50}$/, 'First name should only contain letters')
  //   .required('Required *'),
  // last_name: yup
  //   .string()
  //   .matches(/^[A-Za-z\s'-]{1,50}$/, 'Last name should only contain letters')
  //   .required('Required *'),
  // status: yup.string(),
  // care_coordinator_name: yup.string(),
  email: yup
    .string()
    .email()
    .required('Required *'),
  location: yup.string().required('Required *'),
  // capacity: yup
  //   .number()
  //   // .required('Required *')
  //   .positive()
  //   .integer(),
  // health_plans: yup.array(),
  // states: yup.array(),
  // counties: yup.array(),
  // zip_codes: yup.array(),
});

export const careManagerInitialValues = {
  // first_name: '',
  // last_name: '',
  care_coordinator_name: '',
  email: '',
  location: '',
  capacity: 40,
  status: 'active',
  health_plans: [],
  states: [],
  counties: [],
  // zip_codes: [],
};
