import React, {useCallback, useEffect, useState} from 'react';
import {Pencil, Plus, Trash, TrashSimple, UploadSimple} from 'phosphor-react';
import TableWrapper from 'components/common/table-wrapper';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import CustomModal from 'components/common/modal';
import CareManagerForm from './CareManagerForm';
import {
  createLeadCareManager,
  deleteLeadCareManager,
  getLeadCareManagers,
  updateLeadCareManager,
  deleteBulkLeadCareManager,
  getZipCodes,
} from 'containers/leadcaremanager/api';
import {getCounties, getStates, getHealthPlans} from 'containers/policies/api';
import {formatDate, highlightFirstMatch, capitalizeName, capitalizePlace} from 'utils/common';
import Tooltip from 'components/common/tooltip';
import debounce from 'lodash/debounce';

let timeout;
const Leadcaremanagers = () => {
  // const MAX_CAPACITY = 49;
  const [managersList, setManagersList] = useState();
  const [zipCodeList, setZipCodeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [meta, setMeta] = useState({...initialMetaForTable, status: 'active'});
  const [loading, setLoading] = useState(true);
  const [selectedManagers, setSelectedManagers] = useState(0);
  const [isManagerModalVisible, setIsManagerModalVisible] = useState(false);
  const [currentLeadCareManagerEdit, setCurrentLeadCareManagerEdit] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);
  const [zipcodeSearch, setZipcodeSearch] = useState('');

  const [healthPlansList, setHealthPlansList] = useState([]);
  const [countiesList, setCountiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [countiesSearch, setcountiesSearch] = useState('');
  const [statesSearch, setstatesSearch] = useState('');
  const [healthPlanSearch, sethealthPlanSearch] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handleSetStatusFilter = selectedValue => {
    setMeta(prevMeta => ({...prevMeta, status: selectedValue}));
    handleRefreshPage();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  // const updateZipCodesAttributes = (old = [], latest = []) => {
  //   if (latest.length === 0) return old.map(el => ({...el, _destroy: 'true'}));
  //   if (old.length === 0) return latest.map(el => ({code: el.label}));
  //   const persistedAttributes = old.filter(el => latest.some(item => item.label === el.code));
  //   const removedAttributes = old
  //     .filter(el => latest.some(item => item.label !== el.code))
  //     .map(el => ({...el, _destroy: 'true'}));
  //   const newAttributes = latest
  //     .filter(el => old.some(item => item.code !== el.label))
  //     .map(el => ({id: el.value, code: el.value}));
  //   return [...persistedAttributes, ...removedAttributes, ...newAttributes];
  // };
  const handleManagerSubmission = async manager => {
    try {
      if (typeof currentLeadCareManagerEdit === 'object') {
        const {id} = manager;
        // const old_zip_codes = currentLeadCareManagerEdit.zip_codes;
        // const new_zip_codes = manager.zip_codes;
        // const updated_zip_codes = updateZipCodesAttributes(old_zip_codes, new_zip_codes);
        // const {zip_codes, ...rest} = manager;
        // const result = await updateLeadCareManager({...rest, zip_codes_attributes: updated_zip_codes}, id);
        const payload = {
          ...manager,
          // zip_codes: manager.zip_codes.map(el => {
          //   return el.value;
          // }),
          county_ids: manager.counties.map(el => {
            return el.value;
          }),
          state_ids: manager.states.map(el => {
            return el.value;
          }),
          health_plan_ids: manager.health_plans.map(el => {
            return el.value;
          }),
        };

        const result = await updateLeadCareManager({lead_care_manager: payload}, id);

        if (result) {
          handleCloseManagerModal();
          handleRefreshPage();
        }
      } else {
        const payload = {
          ...manager,
          // zip_codes: manager.zip_codes.map(el => {
          //   return el.value;
          // }),
          county_ids: manager.counties?.map(el => {
            return el.value;
          }),
          state_ids: manager.states?.map(el => {
            return el.value;
          }),
          health_plan_ids: manager.health_plans?.map(el => {
            return el.value;
          }),
        };

        delete payload.health_plans;
        delete payload.states;
        delete payload.counties;

        const result = await createLeadCareManager({lead_care_manager: payload});
        if (result) {
          handleCloseManagerModal();
          handleRefreshPage();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteLeadCareManager = async id => {
    const result = await deleteLeadCareManager(id);
    if (result) handleRefreshPage();
  };
  const handleBulkDeleteLeadCareManager = async id => {
    const selectedManagerIds = managersList?.filter(manager => manager.checked).map(manager => manager.id);
    const payload = {
      ids: selectedManagerIds,
    };
    const result = await deleteBulkLeadCareManager(payload);
    if (result) handleRefreshPage();
  };

  const handleCloseManagerModal = () => {
    setIsManagerModalVisible(false);
    setCurrentLeadCareManagerEdit('');
  };
  const handleOpenManagerModal = () => {
    setIsManagerModalVisible(true);
  };
  const fetchQuestions = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getLeadCareManagers(meta);
      if (result['lead_care_managers']) {
        const data = result?.lead_care_managers.map(item => ({...item, checked: selectAll}));
        setManagersList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  // const fetchZipCodes = useCallback(async () => {
  //   try {
  //     const result = await getZipCodes({...initialMetaForTable, search: zipcodeSearch});
  //     if (result['zip_codes']) {
  //       const data = result.zip_codes;
  //       setZipCodeList(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [zipcodeSearch]);
  // const debouncedFetchZipCode = useCallback(
  //   debounce(searchString => {
  //     fetchZipCodes(searchString);
  //   }, DEBOUNCE_DELAY),
  //   [],
  // );

  // useEffect(() => {
  //   debouncedFetchZipCode(zipcodeSearch);
  // }, [zipcodeSearch]);
  const fetchZipCodes = useCallback(async searchString => {
    try {
      const result = await getZipCodes({...initialMetaForTable, search: searchString});
      if (result.zip_codes) {
        // Change to dot notation for property access
        const data = result.zip_codes;
        setZipCodeList(data);
      }
    } catch (error) {
      console.error(error); // Change to console.error for error logging
    }
  }, []);

  const debouncedFetchZipCode = useCallback(
    debounce(searchString => {
      fetchZipCodes(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );
  const fetchHealthPlan = useCallback(async searchString => {
    const result = await getHealthPlans({...initialMetaForTable, search: searchString});

    if (result['health_plans']) {
      const data = result?.health_plans;
      setHealthPlansList(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const debouncedFetchHealthPlan = useCallback(
    debounce(searchString => {
      fetchHealthPlan(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );
  // const fetchCounties = useCallback(async searchString => {
  //   const result = await getCounties({...initialMetaForTable, search: searchString});

  //   if (result['counties']) {
  //     const data = result?.counties;
  //     setCountiesList(data);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const debouncedFetchCounties = useCallback(
  //   debounce(searchString => {
  //     fetchCounties(searchString);
  //   }, DEBOUNCE_DELAY),
  //   [],
  // );
  const fetchStates = useCallback(async searchString => {
    const result = await getStates({...initialMetaForTable, search: searchString});

    if (result['states']) {
      const data = result?.states;
      setStatesList(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFetchStates = useCallback(
    debounce(searchString => {
      fetchStates(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );
  useEffect(() => {
    debouncedFetchZipCode(zipcodeSearch);
  }, [debouncedFetchZipCode, zipcodeSearch]);

  const handleZipcodeSearch = searchString => {
    setZipcodeSearch(searchString);
  };

  useEffect(() => {
    // fetchZipCodes();
    fetchQuestions();
  }, [fetchQuestions]);
  useEffect(() => {
    setSelectedManagers(
      selectAll
        ? managersList.length
        : managersList?.reduce((accumulator, item) => {
            if (item.checked) {
              return accumulator + 1;
            } else return accumulator;
          }, 0),
    );
  }, [managersList, selectAll]);
  useEffect(() => {
    debouncedFetchHealthPlan(healthPlanSearch);
  }, [debouncedFetchHealthPlan, healthPlanSearch]);
  // useEffect(() => {
  //   debouncedFetchCounties(countiesSearch);
  // }, [debouncedFetchCounties, countiesSearch]);
  useEffect(() => {
    debouncedFetchStates(statesSearch);
  }, [debouncedFetchStates, statesSearch]);

  const handleCountiesSearch = searchString => {
    setcountiesSearch(searchString);
  };
  const handleStatesSearch = searchString => {
    setstatesSearch(searchString);
  };
  const handleHealthplanSearch = searchString => {
    sethealthPlanSearch(searchString);
  };
  console.log(
    '==> healthPlansList',
    currentLeadCareManagerEdit,
    '==> countiesList',
    countiesList,
    '==> stateList',
    statesList,
  );
  return (
    <>
      <TableWrapper
        searchPlaceholder="Search LCM Supervisors"
        setSearhQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        selectedItems={selectedManagers}
        isLoading={loading}
        actionButtons={
          selectedManagers > 0
            ? [
                // {
                //   label: `Delete ${selectedManagers} Items`,
                //   classes: 'danger-btn m-0-imp',
                //   icon: <TrashSimple size={24} className="me-3" />,
                //   handleClick: handleBulkDeleteLeadCareManager,
                // },
              ]
            : [
                {
                  label: meta.status === 'inactive' ? 'Inactive' : meta.status === 'active' ? 'Active' : 'Status',
                  selectedFilter: meta.status,
                  handlefilterSelect: selectedValue => handleSetStatusFilter(selectedValue),
                  dropdown: true,
                  dropdownOptions: [
                    {
                      label: 'Active',
                      value: 'active',
                    },
                    {
                      label: 'Inactive',
                      value: 'inactive',
                    },
                  ],
                },
                // {
                //   label: 'Upload File',
                //   classes: 'secondary-btn',
                //   icon: <UploadSimple size={24} className="me-3" />,
                //   handleClick: () => {},
                // },
                {
                  label: 'Add LCM Supervisor',
                  classes: 'primary-btn',
                  icon: <Plus size={24} className="me-3" />,
                  handleClick: () => {
                    handleOpenManagerModal();
                  },
                },
              ]
        }
      >
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-rows">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  {/* <th>Last Name</th> */}
                  <th>Email</th>
                  <th>HP</th>
                  <th>States</th>
                  <th>Counties</th>

                  {/* <th>Capacity Limit</th> */}
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {managersList?.map((manager, index) => (
                  <tr key={index}>
                    {/* <td>{highlightFirstMatch(`${capitalizeName(manager.first_name)} `, meta.search)}</td> */}
                    {/* <td>{highlightFirstMatch(`${capitalizeName(manager.last_name)} `, meta.search)}</td> */}
                    <td>{highlightFirstMatch(`${capitalizeName(manager.care_coordinator_name)} `, meta.search)}</td>
                    <td>{highlightFirstMatch(manager.email, meta.search)}</td>
                    <td>{highlightFirstMatch(manager.health_plans_names, meta.search)}</td>
                    <td>{highlightFirstMatch(manager.states_names, meta.search)}</td>
                    <td>{highlightFirstMatch(manager.counties_names, meta.search)}</td>

                    {/* <td>{`${manager.capacity_used + ' / ' + manager.capacity}`}</td> */}
                    <td>{manager.created_at}</td>

                    <td>
                      <Tooltip title="Edit">
                        <Pencil
                          size={24}
                          className="opacity-50 me-4"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setCurrentLeadCareManagerEdit(manager);
                            handleOpenManagerModal();
                          }}
                        />
                      </Tooltip>{' '}
                      {/* <Tooltip title="delete">
                        <Trash
                          size={24}
                          className="ms-3 opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            manager?.id && handleDeleteLeadCareManager(manager.id);
                          }}
                        />
                      </Tooltip> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </TableWrapper>
      {isManagerModalVisible && (
        <CustomModal
          size="sm"
          show
          onHide={handleCloseManagerModal}
          heading={`${currentLeadCareManagerEdit ? 'Edit' : 'Add'} LCM Supervisor`}
        >
          <CareManagerForm
            handleQuestionSubmittion={handleManagerSubmission}
            handleClose={handleCloseManagerModal}
            currentLeadCareManagerEdit={{
              ...currentLeadCareManagerEdit,
              // zip_codes: currentLeadCareManagerEdit.zip_codes.map(el => ({
              //   value: el.code,
              //   label: `${el.code} ${el.city?.name || el.city || ''} ${el.county?.name || el.county || ''}`,
              // })),
              ...(currentLeadCareManagerEdit.states?.length && {
                states: currentLeadCareManagerEdit.states?.map(el => ({
                  value: el.id,
                  label: el.name,
                })),
              }),
              ...(currentLeadCareManagerEdit.counties?.length && {
                counties: currentLeadCareManagerEdit.counties?.map(el => ({
                  value: el.id,
                  label: el.name,
                })),
              }),
              ...(currentLeadCareManagerEdit.health_plans?.length && {
                health_plans: currentLeadCareManagerEdit.health_plans?.map(el => ({
                  value: el.id,
                  label: el.name,
                })),
              }),
            }}
            zipCodeList={zipCodeList}
            handleZipcodeSearch={handleZipcodeSearch}
            healthPlansList={healthPlansList}
            countiesList={countiesList}
            stateList={statesList}
            handleCountiesSearch={handleCountiesSearch}
            handleStatesSearch={handleStatesSearch}
            handleHealthplanSearch={handleHealthplanSearch}
            setCountiesList={setCountiesList}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Leadcaremanagers;
