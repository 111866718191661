import React, {useCallback, useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {showSurvey, getLeadCareManagersByLocation} from './api';
import {initialMetaForTable} from 'constants/common';
import {getOutreachLeaders} from 'containers/outreachleaders/api';
import {getLeadCareManagers} from 'containers/leadcaremanager/api';
import SurveyComponent from 'components/survey/Survey';
import BSLoader from 'components/common/loader';

const Survey = () => {
  const {id} = useParams();
  const location = useLocation();
  const {zip_code} = location?.state || {};
  const {code} = zip_code || {};
  const [surveyId, setSurveyId] = useState('');
  const [questions, setQuestions] = useState([]);
  // const [leadcareManagers, setLeadcareManagers] = useState([]);
  const [selectedOutreachLeader, setSelectedOutreachLeader] = useState('');
  const [meta, setMeta] = useState(initialMetaForTable);
  const [lcmLoading, setLcmLoading] = useState(false);
  const [surveyLoading, setsurveyLoading] = useState(true);
  const [surveyStartedAt, setSurveyStartedAt] = useState('');

  // const fetchLeadcareManagers = useCallback(async () => {
  //   try {
  //     setLcmLoading(true);
  //     // const result = await getLeadCareManagers({...meta, filter: 'active', search: code});
  //     const result = getLeadCareManagersByLocation(id);
  //     if (result?.lead_care_managers) {
  //       const data = result?.lead_care_managers
  //         .map(item => {
  //           const usedPercentage = (item.capacity_used / item.capacity) * 100;
  //           let color = 'black';

  //           if (usedPercentage > 74 && usedPercentage <= 99) {
  //             color = 'orange';
  //           } else if (usedPercentage > 99) {
  //             color = 'red';
  //           }

  //           return {
  //             question_option_id: item.id,
  //             value: item.care_coordinator_name,
  //             capacity_exceeded: item.capacity_exceeded,
  //             usedPercentage,
  //             color,
  //           };
  //         })
  //         .sort((a, b) => a.usedPercentage - b.usedPercentage);
  //       setLeadcareManagers(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLcmLoading(false);
  //   }
  // }, [code, meta]);

  const fetchSurvey = useCallback(async () => {
    try {
      setsurveyLoading(true);
      const result = await showSurvey(id);
      if (result?.survey_response) {
        setSurveyId(result.survey_response?.id);
        localStorage.setItem('survey', JSON.stringify({created_at: result.survey_response.created_at}));
        setSurveyStartedAt(result.survey_response.created_at);
        setQuestions(result.survey_response?.answers);
        setSelectedOutreachLeader(result.survey_response?.lead_care_manager || undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setsurveyLoading(false);
    }
  }, [id]);

  useEffect(() => {
    // fetchLeadcareManagers();
    fetchSurvey();
  }, [fetchSurvey]);

  return (
    <>
      {surveyLoading ? (
        <div
          className="bg-white "
          style={{width: '70%', display: 'flex', margin: 'auto', justifyContent: 'center', marginTop: '30px'}}
        >
          <BSLoader className="my-5" />
        </div>
      ) : (
        <SurveyComponent
          surveyQuestions={questions}
          surveyId={surveyId}
          // outreachLeadersList={leadcareManagers}
          // outreachLeadersLength={leadcareManagers.length}
          selectedOutreachLeader={selectedOutreachLeader}
          lcmLoading={lcmLoading}
          surveyLoading={surveyLoading}
          surveyStartedAt={surveyStartedAt}
        />
      )}
    </>
  );
};

export default Survey;
